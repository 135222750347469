<template>
  <div class="airship-container">
    <img src="../materials/airship_text.png" />
  </div>
</template>

<script>
export default {
  name: "Airship"
};
</script>

<style scoped lang="scss">
.airship-container {
  width: 27%;
  position: absolute;
  left: 0;
  top: 209px;
  animation-name: airship;
  animation-duration: 20s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  img {
    width: 100%;
  }
}
@keyframes airship {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
</style>
