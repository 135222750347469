<template>
  <!-- Temporary hide -->
  <div style="display: none">
    <div class="sponser-section-title">合作夥伴 / Partners & Sponsors</div>
    <div v-for="index in 5" :key="index">
      <Corporation
        :group="groups[index-1]"
        :images="images[index-1]"
        :number="numbers[index-1]"
        :names="names[index-1]"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import Corporation from "../components/Corporation.vue";
export default {
  name: "Sponsers",
  components: {
    Corporation
  },
  data() {
    return {
      groups: ["主辦單位", "協辦單位", "合作單位", "指導單位", "贊助廠商"],
      names: [
        ["國立明通大學", "國立新竹教育大學"],
        [
          "國立新竹教育大學",
          "國立新竹教育大學",
          "國立新竹教育大學",
          "國立新竹教育大學"
        ],
        [],
        [],
        ["Amazon Web Services", "Google"]
      ],
      // eslint-disable-next-line
      images: [
        [require("../materials/NCTU.png"), require("../materials/NTHU.png")],
        [
          require("../materials/NCTU.png"),
          require("../materials/NTHU.png"),
          require("../materials/NCTU.png"),
          require("../materials/NTHU.png")
        ],
        [],
        [],
        [
          require("../images/sponsors/aws-logo.png"),
          require("../images/sponsors/google-logo.png")
        ]
      ],
      numbers: [2, 4, 0, 0, 2]
    };
  }
};
</script>

<style scoped lang="scss">
.sponser-section-title {
  font-size: 50px;
  color: #b1b1b1;
  margin-left: 13%;
  margin-top: 210px;
  margin-bottom: 103px;
}
@media (max-width: 500px) {
  .sponser-section-title {
    font-size: 40px;
    margin-left: 5%;
  }
}
</style>
