<template>
  <div class="register-info-container">
    <div class="register-info">
      <div class="subtitle">報名資訊</div>
      <div class="content">
        <b>報名日期：</b>
        9/15 - 10/7 23:59
        <br />
        <br />
        <b>報名費用：</b>每人 400 + 200 元保證金
        <br />其中400元，為活動兩天基本支出：三餐消夜、場地清潔、保險費。200元為保證金，將於活動結束後退還。
        <br />
        <br />
        <b>報名資格：</b>
        <br />創客組 : 不限資格
        <br />黑客組 : 全台大專院校生及研究生
        <br />
        <br />
        <b>報名方式：</b>自行組隊報名，每隊3~5人。並無限制每個人的專長與能力，但每隊至少要有一人具備使用企業資源的能力，並且儘量在專長中說明團隊或個人所具備的技術或其他能力。報名黑客組者，將根據報名志願序，每組分配到一家企業，其中有參與過該家企業賽前工作坊者將優先錄取。
      </div>
    </div>
    <div class="register-schedule">
      <div class="subtitle">報名流程</div>
      <div class="content">
        10/8 (二) 12:00 公布正取隊伍與所屬企業
        <br />
        <br />10/12(六) 23:59 正取隊伍繳費截止
        <br />
        <br />10/13(日) 12:00 公布備取隊伍與所屬企業
        <br />
        <br />10/17(四) 23:59 備取隊伍繳費截止
      </div>
      <div class="button-container">
        <a href="https://signup.meichuhackathon.org"><button>立即報名</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterInfo",
  data() {
    return {
      register_info_content:
        "<b>報名日期</b> : 即日起 - 10/03報名費用 : 每人 400 + 200 保證金(全程參與活動會全額退還保證金)，由團隊代表統一匯款。黑客組根據報名志願序，每組分配到一家企業每一隊3~5人，團隊中至少要有一人具備使用企業資源的能力，並且儘量在專長中說明團隊或個人所具備的技術或其他能力。"
    };
  }
};
</script>

<style scoped lang="scss">
.register-info-container {
  border: 6px solid #707070;
  display: flex;
  justify-content: space-around;
  width: 66%;
  color: #b1b1b1;
  margin-top: 70px;
}
.register-info {
  width: 38%;
  margin: 0 8% 105px 8%;
  .subtitle {
    font-size: 50px;
    margin-top: 66px;
    margin-bottom: 50px;
  }
  .content {
    font-size: 20px;
  }
}
.register-schedule {
  width: 38%;
  margin-right: 5%;
}
.button-container {
  text-align: center;
  margin-top: 107px;
}
button {
  width: 85.9%;
  background-color: Transparent;
  color: #b1b1b1;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  padding: 18px 0 18px 0;
  cursor: pointer;
}
@media (max-width: 1248px) {
  .register-info-container {
    width: 90%;
  }
  .register-info {
    margin: 0 8% 20px 8%;
    .subtitle {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .content {
      font-size: 20px;
    }
  }
}
@media (max-width: 790px) {
  .register-info-container {
    width: 90%;
    flex-direction: column;
  }
  .register-info {
    width: 80%;
    .subtitle {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .content {
      font-size: 20px;
    }
  }
  .register-schedule {
    width: 80%;
    margin: 0 8% 40px 8%;
  }
  .button-container {
    margin-top: 40px;
    margin-bottom: 0px;
    .button {
      font-size: 20px;
    }
  }
}
</style>
