<template>
  <div class="flex-container">
    <div class="flex-item title">
      <div @click="toIntro">
        <NavBarItem :title_en="Intro" :title_ch="Intro_ch" :title_ch2="Intro_ch2" />
      </div>
      <div @click="toJoinUs">
        <NavBarItem :title_en="JoinUs" :title_ch="JoinUs_ch" />
      </div>
    </div>
    <div class="flex-item logo">
      <img src="../materials/Logo.png" alt />
      <hr />
      <div>2019 新竹x梅竹黑客松</div>
    </div>
    <div class="flex-item title">
      <div @click="toSchedule">
        <NavBarItem :title_en="Schedule" :title_ch="Schedule_ch" />
      </div>
      <div @click="toStaff">
        <NavBarItem :title_en="Staff" :title_ch="Staff_ch" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBarItem from "./NavBarItem.vue";

export default {
  name: "NavBar",
  data() {
    return {
      Intro: "Story",
      Intro_ch: "故事",
      Intro_ch2: "",
      JoinUs: "Join us",
      JoinUs_ch: "報名資訊",
      Schedule: "Schedule",
      Schedule_ch: "活動日程",
      Staff: "Staff",
      Staff_ch: "工作人員"
    };
  },
  components: {
    NavBarItem
  },
  methods: {
    toIntro() {
      this.$emit("toIntro");
    },
    toJoinUs() {
      this.$emit("toJoinUs");
    },
    toSchedule() {
      this.$emit("toSchedule");
    },
    toStaff() {
      this.$emit("toStaff");
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  .title {
    display: flex;
    justify-content: space-around;
    flex: 1;
    align-items: flex-start;
    padding-top: 84px;
    // padding-top: 30px;
  }
  .logo {
    text-align: center;
    font-weight: bold;
    color: #599fa4;
    font-size: 22px;
    padding-top: 58px;
    // padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
hr {
  border: 1px solid #599fa4;
}
</style>
