<template>
  <div class="times-container">
    <div class="date-title">
      <u>Day 01 (10/26)</u>
    </div>
    <div class="schedule-details-container">
      <div class="schedules">
        <div>
          08:30-09:00
          <br />參賽者報到
        </div>
        <div>
          09:00-09:30
          <br />開幕致詞&amp;
          <br />競賽說明
        </div>
        <div>
          09:30-10:00
          <br />企業題目講解
        </div>
        <div>
          10:30-12:00
          <br />開始 Coding
        </div>
      </div>
      <div class="schedules">
        <div>
          12:00-13:30
          <br />午餐
        </div>
        <div>
          13:30-18:00
          <br />持續 Coding
        </div>
        <div>
          18:00-19:30
          <br />晚餐與交流
        </div>
        <div>
          19:30
          <br />戰鬥永無止境
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleDetails1RWD"
};
</script>

<style scoped lang="scss">
.times-container-rwd {
  margin-bottom: 40px;
  width: 55%;
  animation-name: schedules;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.date-title {
  font-family: Apple Chancery, cursive;
  font-size: 35px;
  color: #ffffff;
  margin-left: 3%;
  margin-bottom: 85px;
}
.schedule-details-container {
  display: flex;
  justify-content: space-around;
}
.schedules {
  font-size: 20px;
  margin: -60px 30px 0px 30px;
  color: #b1b1b1;
}
.schedules > div {
  margin-bottom: 75px;
  text-align: center;
}
</style>