<template>
  <div class="footer-right">
    <div class="title">聯絡我們 Contact us</div>
    <div class="line">
      <img src="../materials/long_line.png" alt />
    </div>
    <div class="contact-information-container">
      <div class="img-container">
        <img src="../materials/facebook.png" alt />
      </div>
      <a
        href="https://www.facebook.com/HackMeiChu/"
        target="_blank"
        class="fb-link"
      >https://www.facebook.com/HackMeiChu/</a>
    </div>
    <div class="contact-information-container">
      <div class="img-container">
        <img src="../materials/mail.png" alt />
      </div>
      <a href="mailto:support@meichuhackathon.org" target="_blank">support@meichuhackathon.org</a>
    </div>
    <div class="contact-information-container">
      <div class="img-container">
        <img src="../materials/phone.png" alt />
      </div>
      <div>(Please use the email above instead.)</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs"
};
</script>

<style scoped lang="scss">
.footer-right {
  margin-top: 43px;
}
.title {
  font-size: 18px;
}
.contact-information-container {
  display: flex;
  margin-top: 28px;
}
.img-container {
  margin-right: 6%;
}
a:link,
a:visited {
  color: #b1b1b1;
  text-decoration: none;
}
@media (max-width: 900px) {
  .line {
    > img {
      width: 80%;
    }
  }
}
@media (max-width: 390px) {
  .contact-information-container {
    font-size: 15px;
  }
  .img-container > img {
    size: 80%;
  }
}
</style>
