<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
// eslint-disable-next-line
import Layout from "./Layout/Layout.vue";

export default {
  components: {
    Layout
  }
};
// Mask before fully loaded.
/*var elDiv = document.createElement("div");
elDiv.innerHTML = '<div style="display: block" id="hideAll">&nbsp;</div>';
document.body.appendChild(elDiv);*/
// Onload.
/*function check() {
  // See if all images loaded
  var imgs = document.images;
  var count = 0;
  var len = imgs.length;
  for (var img of imgs) {
    if (img.complete) {
      count++;
    }
  }
  if (count == len) {
    document.getElementById("hideAll").style.display = "none";
  } else {
    setTimeout(check, 100);
  }
  // document.getElementById("hideAll").style.display = "none";
}*/
/*window.onload = function() {
  document.getElementById("hideAll").style.display = "none";
};*/
</script>

<style>
body {
  /* Reduce bg load flickering. */
  background-color: black;
  background-image: url("materials/background.png");
  background-attachment: fixed;
  background-size: cover;
  overflow-x: hidden;
  margin: 0;
}
html * {
  font-family: Microsoft JhengHei UI, PingFang TC !important;
}
/*#hideAll {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: black;
  z-index: 99;
}*/
</style>
