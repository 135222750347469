<template>
  <div class="intro-section">
    <div class="intro-title">故事 / Story</div>
    <div class="intro-flex-container">
      <dir class="intro-box">
        <div class="intro-container">
          <div class="intro-subtitle">梅竹黑客松</div>
          <div class="intro-subtitle">Meichu Hackathon</div>
          <div class="intro-content">
            梅竹黑客松，是一個致力於打造學生科技創新空間的社群。結合企業端的資源與技術，以及學生的創意與想法，我們持續地推動一場全台最大校園「不間斷的科技創新運動」：黑客松比賽。
            <br />
            <br />在我們所打造的環境中，學生將獲得企業指導與業界資源，利用自我能力與創意來回應各家企業所給出的問題與挑戰；企業將從中挖掘人才，給予實習機會，並藉由學生的點子刺激企業內部想法。
          </div>
        </div>
        <div class="intro-container paragraph2">
          <div class="intro-subtitle">2019</div>
          <div class="intro-subtitle">新竹 x 梅竹黑客松</div>
          <div class="intro-content">
            2019，我們推動更多的可能。
            <br />
            <br />新竹市政府加入，成立「創客組」：讓學生在政府的資源下，利用科技來創造社會議題新的完善可能。
            <br />
            <br />梅竹黑客松 賽前實作工作坊：針對有興趣參與黑客松者、對該屆合作企業技術規格有興趣者，為了讓學生對於比賽更了解、刺激與企業更深的對話和交流，我們將於9/28(六)、9/29(日)，由各間企業分派技術人員，針對自身題目進行實作工作坊。
            <br />
            <br />當日現場交流平台：專屬梅竹黑客松參與人員間的交流社群。打造志同道合的學生間交流，認識更多夥伴、各地大神的機會。除了競爭，更創造學生間切磋、分享的機會。
          </div>
        </div>
      </dir>
      <div class="intro-pictures-container">
        <div>
          <img class="slides" src="../images/activity/photo-1.jpg" />
          <img class="slides" src="../images/activity/photo-2.jpg" />
          <img class="slides" src="../images/activity/photo-3.jpg" />
          <img class="slides" src="../images/activity/photo-4.jpg" />
        </div>
        <div class="dots">
          <div class="checkmark" @click="changeColor(0)"></div>
          <div class="checkmark" @click="changeColor(1)"></div>
          <div class="checkmark" @click="changeColor(2)"></div>
          <div class="checkmark" @click="changeColor(3)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  data() {
    return {
      clicked: false,
      myIndex: 0,
      max: 4
    };
  },
  methods: {
    updateSelected() {
      const x = document.getElementsByClassName("slides");
      const dots = document.getElementsByClassName("checkmark");
      for (let i = 0; i < this.max; i++) {
        x[i].style.display = "none";
        dots[i].style.backgroundColor = "transparent";
      }
      x[this.myIndex].style.display = "block";
      dots[this.myIndex].style.backgroundColor = "#B1B1B1";
    },
    changeColor(idx) {
      this.clicked = true;
      this.myIndex = idx;
      this.updateSelected();
    },
    autoSlide() {
      // Skip auto slide once after clicked.
      if (!this.clicked) {
        this.myIndex = (this.myIndex + 1) % this.max;
        this.updateSelected();
      }
      this.clicked = false;
      setTimeout(this.autoSlide, 2000);
    }
  },
  mounted() {
    this.autoSlide();
  }
};
</script>

<style scoped lang="scss">
.intro-title {
  font-size: 50px;
  color: #b1b1b1;
  margin-left: 13%;
  margin-bottom: 78px;
  margin-top: -500px;
}
.intro-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.intro-box {
  margin-left: 18.6%;
  width: 40%;
  .intro-subtitle {
    font-size: 30px;
    color: #707070;
    font-weight: bold;
  }
  .intro-content {
    font-size: 20px;
    color: #b1b1b1;
    width: 85%;
    margin-top: 20px;
  }
  .paragraph2 {
    margin-top: 48px;
  }
}

.intro-pictures-container {
  text-align: center;
  margin-top: 182px;
  margin-right: 11.1%;
  width: 40%;
  .slides {
    width: 100%;
    display: none;
  }
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 51px;
    .checkmark {
      height: 18px;
      width: 18px;
      border: 2px solid #707070;
      border-radius: 50%;
      margin: 3.6%;
    }
  }
}
@media (max-width: 1440px) {
  .intro-box {
    margin-left: 10%;
    width: 40%;
  }
  .intro-pictures-container {
    margin-right: 10%;
    margin-top: 300px;
    width: 40%;
  }
}
@media (max-width: 1176px) {
  .intro-box {
    margin-left: 5%;
    width: 45%;
  }
  .intro-pictures-container {
    margin-right: 5%;
    margin-top: 300px;
    width: 45%;
  }
}
@media (max-width: 1024px) {
  .intro-title {
    margin-top: 200px;
  }
  .intro-flex-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .intro-box {
    width: 90%;
  }
  .intro-pictures-container {
    margin-top: 20px;
    width: 70%;
  }
}
@media (max-width: 828px) {
}
@media (max-width: 732px) {
  .intro-flex-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .intro-pictures-container {
    margin-top: 20px;
    width: 70%;
  }
  .intro-box {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .intro-title {
    font-size: 40px;
    margin-left: 5%;
  }
}
</style>
