<template>
  <div class="department-container">
    <div class="department">
      <img :src="department_image" class="department-img" />
      <div class="department-name">{{ department_name }}</div>
    </div>
    <div class="line" v-if="names">
      <img src="../materials/department_line.png" />
    </div>
    <div class="names">
      <div
        v-for="(name, index) in names"
        :key="name"
      >{{ name + ((index !== names.length - 1) ? '／' : '')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StaffsComponent",
  props: ["department_image", "department_name", "names"]
};
</script>

<style scoped lang="scss">
.department-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: #b1b1b1;
  margin-top: 60.1px;
  .department-name {
    font-size: 25px;
    margin-top: 22.5px;
  }
  .line {
    margin-left: 3%;
    margin-right: 3%;
  }
  .names {
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 54.4%;
  }
}
@media (max-width: 1090px) {
  .department-img {
    width: 80%;
  }
  .department-container {
    width: 130%;
    .line {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}
@media (max-width: 730px) {
  .department-img {
    width: 75%;
  }
  .department-container {
    width: 130%;
    .line {
      margin-left: 8%;
      margin-right: 8%;
    }
  }
}
@media (max-width: 675px) {
  .department-container {
    .names {
      font-size: 17px;
    }
    .department-name{
      font-size: 20px;
    }
  }
}
@media (max-width: 600px) {
  .department-img {
    width: 65%;
  }
  .department-container {
    .names {
      font-size: 15px;
    }
    .line {
      margin-left: 5%;
      margin-right: 8%;
    }
  }
}
@media (max-width: 540px) {
  .department-img {
    width: 55%;
  }
  .department-container {
    .department-name {
      font-size: 20px;
    }
    .names {
      font-size: 15px;
    }
    .line {
      margin-left: -5%;
      margin-right: 5%;
    }
  }
}
// @media (max-width: 420px) {
//   .department-img {
//     width: 35%;
//   }
//   .department-container {
//     width: 150%;
//     .department-name {
//       font-size: 15px;
//     }
//   }
// }
// @media (max-width: 360px) {
//   .department-img {
//     width: 30%;
//   }
// }
@media (max-width: 500px) {
  .department-container {
    width: 300px;
    margin-left: -50px;
    .department-name {
      font-size: 25px;
    }
    .names {
      font-size: 18px;
    }
    .line {
      margin-left: -5%;
      margin-right: 5%;
    }
  }
}
// @media (max-width: 485px) {
//   .department-container {
//     width: 100%;
//     border: 1px solid red;
//   }
// }
</style>
