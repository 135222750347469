<template>
  <div class="staffs-section-container">
    <div class="staffs-title">工作人員 / Staffs</div>
    <div class="for-centering">
      <div class="staffs-container">
        <div v-for="department in 7" :key="department" class="department-section">
          <StaffsComponent
            :department_image="department_images[department-1]"
            :department_name="departments[department-1]"
            :names="names[department-1]"
          />
        </div>
        <div class="empty-item">
          <StaffsComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import StaffsComponent from "../components/StaffsComponent.vue";
export default {
  name: "Staffs",
  components: {
    StaffsComponent
  },
  data() {
    return {
      departments: ["行政", "設計", "開發", "公關", "總務", "活動", "行銷"],
      department_images: [
        /* eslint-disable */
        require("../materials/admin.png"),
        require("../materials/design.png"),
        require("../materials/develop_v2.png"),
        require("../materials/public-relation.png"),
        require("../materials/general-affair.png"),
        require("../materials/activity.png"),
        require("../materials/marketing.png")
        /* eslint-enable */
      ],
      names: [
        ["江岷錡", "黃靖雯"],
        ["曾敬崴", "孫正", "陳騰鴻", "林彥淳", "陸鏡涵", "周育如"],
        [
          "林奕鑫",
          "孫偉芳",
          "林奕碩",
          "潘怡君",
          "劉雅嫻",
          "黃恒軒",
          "林彥廷",
          "陳劭愷",
          "陳奕君",
          "唐晏湄"
        ],
        ["陳品竹", "柯彥寧", "陳霈耘", "何芊葳", "林詠涵", "劉祖禕"],
        ["鄭伯俞", "李亭妤", "辛孟哲", "游淳閔", "莊景喻"],
        ["陳巧錚", "吳宗逸", "黃家朗", "黃鈺媚", "黎瑄宜", "李宜姍", "歐智行", "楊雅涵"],
        ["陳姿云", "池翊寧", "盧庭誼", "卓依璇","沈品妤"],
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.staffs-section-container {
  width: 100%;
  margin-top: -200px;
}
.staffs-title {
  font-size: 50px;
  color: #b1b1b1;
  margin-left: 13%;
  margin-bottom: 70px;
}
.staffs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 62.1%;
  margin-left: 19%;
  .department-section {
    width: 44%;
  }
}
.for-centering {
  display: flex;
}
@media (max-width: 920px) {
  .staffs-container {
    width: 80%;
    margin-left: 5%;
  }
}
@media (max-width: 730px) {
  .for-centering {
    display: flex;
    justify-content: center;
    align-items: center;
    .staffs-container {
      margin-left: -10%;
      width: 80.1%;
    }
  }
}
@media (max-width: 500px) {
  .staffs-title {
    font-size: 40px;
    margin-left: 5%;
  }
  .staffs-container {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
