<template>
  <div class="rwd-menu">
    <div class="menu">
      <div class="logo">
        <img src="../materials/RWD_logo.png" />
      </div>
      <div class="menu-container">
        <div class="menu-item" @click="toIntro">
          <div class="img-container">
            <img src="../materials/intro_icon.png" />
          </div>
          <div>故事</div>
        </div>
        <div class="menu-item" @click="toJoinUs">
          <div class="img-container">
            <img src="../materials/register_icon.png" />
          </div>
          <div>報名資訊</div>
        </div>
        <div class="menu-item" @click="toSchedule">
          <div class="img-container">
            <img src="../materials/schedule_icon2.png" />
          </div>
          <div>活動日程</div>
        </div>
        <div class="menu-item staffs" @click="toStaff">
          <div class="img-container">
            <img src="../materials/staffs_icon.png" />
          </div>
          <div>工作人員</div>
        </div>
      </div>
      <div class="contact-us">聯絡我們</div>
    </div>
    <div class="footer">
      <div class="copyright">© 2019 MeiChu Hackathon</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    toIntro() {
      this.$emit("toIntro");
    },
    toJoinUs() {
      this.$emit("toJoinUs");
    },
    toSchedule() {
      this.$emit("toSchedule");
    },
    toStaff() {
      this.$emit("toStaff");
    }
  }
};
</script>

<style scoped lang="scss">
.rwd-menu {
  color: #b1b1b1;
  font-size: 13px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16.3px;
  margin-top: 23.7px;
}
.menu-container {
  padding-top: 23px;
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  .menu-item {
    display: flex;
    padding-bottom: 42px;
    cursor: pointer;
    .img-container {
      width: 40px;
      text-align: center;
      margin-left: 6%;
      margin-right: 10%;
    }
  }
  .staffs {
    margin-bottom: -19px;
  }
}
.contact-us {
  margin-left: 6%;
  margin-top: 22.5px;
}
.copyright {
  font-size: 9px;
  text-align: center;
  margin-bottom: 40px;
}
</style>
