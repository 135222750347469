<template>
  <div class="footer">
    <div class="footer-container">
      <div class="logo-container">
        <img src="../materials/Logo_color.png" alt />
      </div>
      <div>
        <ContactUs />
      </div>
    </div>
    <br />
    <div class="copyright">
      © 2019 MeiChu Hackathon
      <br />
      <br />Powered by AWS
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import ContactUs from "../components/ContactUs.vue";
export default {
  name: "Footer",
  components: {
    ContactUs
  }
};
</script>

<style scoped lang="scss">
.footer {
  margin-top: 200px;
  background: #000;
}
.footer-container {
  color: #b1b1b1;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  margin: 100px 5% 0 5%;
}
.logo-container {
  margin-top: 105px;
}
.copyright {
  color: #b1b1b1;
  font-size: 15px;
  text-align: center;
  padding-bottom: 49px;
}
@media (max-width: 900px) {
  .logo-container {
    > img {
      width: 80%;
    }
  }
}
@media (max-width: 620px) {
  .logo-container {
    display: none;
  }
}
</style>
