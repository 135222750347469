<template>
  <div class="corporation-intro-container">
    <div class="return">
      <img class="return-btn" src="../materials/return.png" @click="handleClick" />
    </div>
    <div class="building-and-description-container">
      <div class="building-in-intro">
        <img :src="building_image" />
      </div>
      <div class="description">
        <div class="corporation-name">{{ name }}</div>
        <div>
          <img src="../materials/short_line.png" />
        </div>
        <div class="introduction">
          <div v-for="(description, index) in descriptions[name]" :key="index">
            <div class="description-title">{{ description[0] }}</div>
            <div>
              <img src="../materials/Line_description.png" />
            </div>
            <div class="description-content">
              <span v-html="description[1]"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="scroll-container">
        <div id="up_scroll">
          <img src="../materials/up_scrool.png" />
        </div>
        <div id="down_scroll">
          <img src="../materials/down_scroll.png" />
        </div>
      </div>-->
    </div>
    <div class="bottom-space">
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "CorporationIntro",
  props: ["building_image", "name", "description1", "description2"],
  data() {
    return {
      descriptions: {
        KKBOX: [
          [
            "KKBOX 簡介",
            "KKBOX 是由一群熱愛技術及音樂的工程師於 2005 年創立的品牌，\
            以獨創的雲端技術提供音樂串流服務，讓使用者透過網路即可播放儲存在雲端的歌曲；\
            並以技術加密媒體檔成功為線上音樂和智慧財產權取得完美的平衡，\
            打開了線上音樂合法授權的版權觀念，更成為亞洲市場的標竿品牌。\
            至今，KKBOX 擁有超過 4500 萬首曲目，服務地區包括台灣、香港、日本、新加坡及馬來西亞。\
            KKBOX服務在電腦、智慧型手機或其他行動裝置上都能使用，不只能線上聽音樂，\
            還能下載到裝置中離線收聽，給消費者方便自由、沒有界限的音樂世界！"
          ],
          [
            "題目說明",
            "音樂即生活，讓音樂體驗無所不在！\
<br/>以音樂為核心，利用 KKBOX Open API / IoT SDK 的豐富音樂資料，發揮創意\
<br/>打造無限多音樂相關的有趣應用。"
          ],
          [
            "組別介紹",
            "本次題目將分為 IoT 組及純軟體組，各組別符合條件如下：\
            <ul>\
            <li>IoT 組：使用 KKLINX 及開發版做出相關音樂應用</li>\
            <li>純軟體組：無需使用 KKLINX 及開發版，使用 Open API 做出相關音樂\
應用</li>\
            </ul>"
          ],
          [
            "KKBOX Open API - 讓人人都能成為音樂專家",
            "KKBOX Open API 擁有超過 4,500 萬首歌曲的豐富音樂資料庫，你可以利用各\
種程式語言透過 HTTP API 取得歌手、專輯、歌曲、排行榜等資訊，方便實作\
音樂應用於網頁、Mobile App 及 IoT 設備之中，是提供你取得音樂原料的入\
口！\
<br/>開發者網站：<a class='a-mod' href='https://rebrand.ly/l2mj9s'>https://rebrand.ly/l2mj9s</a>\
<br/>開始使用說明：<a class='a-mod' href='https://docs-zhtw.kkbox.codes/reference?showHidden=1a024'>https://docs-zhtw.kkbox.codes/reference?showHidden=1a024</a>\
<br/>＊此場活動獨家揭露 OAuth 2 使用者授權 API，請參賽者務必從以上「開始使\
用說明」連結進入相關文件"
          ],
          [
            "KKLINX - 讓你的音樂應用活靈活現、沒有界限！",
            "KKLINX 是 KKBOX 的音樂播放函式庫，提供簡單的整合介面，並讓 KKBOX\
用戶透過 KKBOX App 即能操控整合 KKLINX 的裝置或是裝置自行驅動播放選\
定之音樂曲目。我們提供易於給多種語言整合的 MQTT 介面，讓你可以在\
Raspberry 等多種開發板上完全揮灑充滿音符躍動的無限創意！\
            "
          ]
        ],
        羅技電子: [
          [
            "羅技電子簡介",
            "Logitech International S.A. 羅技電子是一家著重創新與品質的瑞士公司，\
其個人週邊產品的設計為消費者提供絕佳的數位體驗。我們從1981年開始\
研發當時所沒有的滑鼠，讓使用者可以用更直覺的方式與個人電腦互動。\
<br/><br/>從成立到現在，我們將專業知識擴展到電腦滑鼠以外的產品設計，以多種介面\
裝置產品大量縮短使用者與電腦、遊戲主機、數位滑鼠或家庭娛樂系統間的\
距離。羅技的產品遍佈全世界的每一個角落，其個人週邊產品(無線和有線)\
創新技術無人可及，尤其在個人電腦瀏覽功能、遊戲、網路通訊、數位滑鼠\
和家庭娛樂控制等產品。我們針對每一種產品類別，研究客戶使用數位裝置\
的方法，然後由設計師與工程師針對那些裝置，設計出更好、更豐富、更舒\
服、更有趣、更有效率、更方便，以及更愉悅的使用體驗。\
"
          ],
          [
            "題目說明",
            "We have several ideas for the challenge, teams can pick one or many. \
<br/><br/>1) How can we leverage the recent developments, in software, AI and machine learning, to boost the productivity of our users? \
<br/><br/>2) Play a game through remote server to replace a local machine equipped with expensive CPU/graphic card. User could use keyboard, mouse, and controller to execute actions and sent over the network to the cloud gaming server. \
<br/><br/>3) Base on work from home. How might we create an environment like an office for virtual teams? How might we improve creativity and productivity for virtual teams? \
<br/><br/>4) There are more and more YouTuber online. How might we integrate keyboard, mouse, webcam, microphone and software to create a more friendly YouTuber environment? \
<br/><br/>5) While people spend a lot of time at their desks every day. How might we link a healthier lifestyle, gamification, muscle memory, Logitech software and product to help users stay healthy at work? \
<br/><br/>Logitech SDK link is <a class='a-mod' href='https://sites.google.com/logitech.com/logi-meichu-hackathon/home'>here</a>. \
"
          ]
        ],
        中華電信: [
          [
            "中華電信簡介",
            "中華電信因應數位匯流與文創風潮，以數位生活為題，打造數位創新系列賽，鼓勵數位創新，透過競賽發掘頂尖創新人才，讓創意獲得實踐，提升台灣創新軟實力！2018年，物聯網將是新興科技發展重點，中華電信希望於不久的將來帶領台灣進軍全球物聯網市場，並推動物聯網教育、向下扎根，透過競賽激發學生參與展現創意。"
          ],
          [
            "IoT大平台簡介",
            "IoT智慧聯網大平台，藉由提供感測資料之收集、處理、儲存、供應及管理等，建構出高可用性、高可靠性、高處理量且符合資訊安全標準的智慧感測資料中心，並透過物聯網技術收納各項設備資訊以進行監測，進而提供相關的加值服務，各細項內容如下：\
            <ul>\
            <li>核心服務：包含了應用與平台，平台與裝置之間基礎相關的服務，涵蓋裝置管理、網路管理、應用開發等工具。</li>\
            <li>智慧應用服務：由中華電信研究院主力研發之AI、區塊鍊、大數據、資安四大領域，融合IoT智慧聯網大平台，提供領域應用服務給使用者。</li>\
            </ul>"
          ],
          [
            "題目說明",
            "<b>智慧生活感測資訊收集與創意應用</b>\
            <br/><br/>以可商轉創意為核心，運用資通訊技術並結合智慧終端裝置，發展整合性物聯網應用服務及解決方案。\
            <ul>\
            <li>IoT大平台服務關聯性：透過前端設備感測器與後端控制應用系統整合，可創造多樣化的服務價值，在設計思考上應考量運用IoT大平台功能，以人本出發，提升使用者體驗之服務。</li>\
            <li>人機介面：IoT大平台上提供多項程式工具，在介面的設計上應考量使用者操作的便利性與畫面視覺體驗。</li>\
            </ul>"
          ],
          [
            "學院綠能智慧園區資料欄位",
            "<a class='a-mod' href='https://drive.google.com/file/d/1IgmhlZ0CATWD01oMKi5RJZJAORxD6x7D/view?usp=sharing'>IOT智慧聯網大平台 資料欄位手冊</a>\
            "
          ]
        ],
        威盛電子: [
          [
            "威盛電子簡介",
            "威盛電子成立於1992年，早期以IC設計為產品發展主軸，為全球IC設計與個人電腦平台解決方案\
的領導廠商，近年致力於人工智慧、物聯網、電腦視覺、無人駕駛汽車、醫療自動化及智慧城市等高\
科技的應用，自行研發的創新型嵌入式系統及設備，憑藉著無與倫比的平台、系統、軟體及客制化的\
服務，提供客戶完整的解決方案，不僅能加速產品開發週期，也可幫客戶洞悉市場的新機會。威盛總\
公司位於新北市新店區，透過全球化佈局，分支機構遍佈美國、歐洲及亞洲，客戶群亦涵蓋至全球高\
科技、電信、電子消費等領域的領先品牌廠商，發展迄今，已成為全球高度整合嵌入式平台及系統解\
決方案領導廠商。"
          ],
          [
            "題目說明",
            "<b>AI影像辨識及自然語言應用</b>\
            <br/>參賽隊伍運用影像辨識及自然語言相關之AI技術能力，將好點子，施作於具備AI算力之系統上， 提出創意應用程式，不設限應用場域，參賽隊伍可影像辨識及自然語言技術擇一施作，或兩者技術皆運用之。"
          ],
          [
            "系統設備說明",
            "VIA提供軟硬體如下:\
          <br/><br/>硬體部份:\
          <ul>\
<li>VIA ALTA DS3系統 (使用Qualcomm® APQ8096SG 嵌入式處理器)</li>\
<li>CSI Camera 1支</li>\
</ul>\
<br/>軟體部份:\
<ul>\
<li>Android 8.0 OS</li>\
<li>VIA AI 影像辨識 SDK</li>\
<li>VIA 語音辨識及自然語言SDK (含Web Server)</li>\
</ul>\
<br/>文件部份:\
<ul>\
<li>VIA AI 影像辨識 SDK 使用手冊(含sample code)</li>\
<li>VIA OLAMI語音辨識及自然語言SDK使用手冊(含sample code)</li>\
</ul>\
"
          ]
        ],
        富比庫: [
          [
            "富比庫簡介",
            "Footprintku represents the building blocks of electronics. \
Launch products quicker and enjoy the benefits of digitization, and intelligent automation. \
 "
          ],
          [
            "題目說明",
            "在使用 EDA tools 做設計時，若遇到非內建的元件，工程師需要從該零件的 PDF 說明文件中，找到腳位圖中腳位編號或其座標所對應的敘述，並自己手動加入該元件。\
參賽隊伍運用圖像辨識和文字搜尋，利用題目提供的文件和圖案，幫助工程師能更方便的對應各個座標、編號及功能。\
<ul>\
<li>目標 1: <br/>利用題目提供的腳位圖，從圖中辨別各個座標內的文字，並在 PDF 文件中搜尋相關的敘述和說明。</li>\
<li>目標 2: <br/>設計使用者介面和視覺化，可以假設已知目標 1 所找到的各資訊。當使用者選取圖中的某個腳位時，將該座標在文件中的相關資訊顯現出來，選取方式和資訊呈現方式不限，提供參加者發想，以能讓工程師在最短的時間內獲得最多有用的資訊為主要目標。</li>\
</ul>\
參賽者可擇一目標實作，或兩個方向皆實作。如兩個目標皆已完成，則可以推廣到一般文件，或是發想其他日常生活應用。\
"
          ]
        ],
        新竹市政府: [
          [
            "新竹市政府簡介",
            "「建立一套機制與方案打開新竹市政府青年事務科！提議、評估、決策交給大家，執行交給政府，讓我們一起落實青年參政，改變家鄉！」\
            <br><br>由於新竹市政府正式啟動青年發展科，專責青年發展事務，期望邀請年輕人站出來，為城市發展注入更多活力，更希望仿效開放政府精神，收納青年對青年發展之意見，開創無限可能。"
          ],
          [
            "題目說明",
            "青年 X 政府，參與、溝通對話及創新發展，例如以下幾個面向，但不侷限這些範圍：\
<ul>\
<li>青年的需求，議題想法或倡議行動等，可以透過何種機制與平台交流溝通對話，讓公部門或政府正確地有效率的回應或推動政策。\
<br>例如：將新竹市政府勞工處網頁利用各種科技工具新增或調整為青年 X 政府雙向溝通平台。</li>\
<li>公部門可以建置什麼管道，讓青年對社會參與更為主動積極。</li>\
<li>青年可以如何與政府攜手共同創新發展，取社會流動的機會，共同營造新世代的社會。</li>\
</ul>\
參考網站資料：\
<ul>\
<li><a class='a-mod' href='https://g0v.tw/zh-TW/'>零時政府</a></li>\
<li><a class='a-mod' href='https://ocf.tw/'>開放文化基金會</a></li>\
</ul>"
          ]
        ]
      }
    };
  },
  methods: {
    handleClick() {
      // document.body.style["overflow-y"] = "auto";
      this.$emit("return");
    }
  }
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 80px 0 5px 0;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: rgb(219, 219, 219);
}
.scroll-container {
  width: 2%;
  margin-right: 15px;
}
.corporation-intro-container {
  display: flex;
  flex-direction: column;
  border: 10px solid #666666;
  border-radius: 49px;
  width: 60%;
  color: #b1b1b1;
  background-color: #112329;
}
.return {
  margin-top: 50px;
  margin-left: 55px;
}
.return-btn {
  cursor: pointer;
}
.building-in-intro {
  // margin-top: 108px;
  margin-left: 3%;
  img {
    // height: 747px;
    height: 76.5vh;
  }
}
.corporation-name {
  font-size: 39px;
}
.building-and-description-container {
  display: flex;
  justify-content: space-around;
}
.description {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 74vh;
}
.introduction {
  // height: 630px;
  padding-right: 20px;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.description-title {
  font-size: 25px;
  margin-top: 43.5px;
}
.description-title {
  margin-bottom: 20px;
}
@media (max-width: 1400px) {
  .corporation-intro-container {
    width: 70%;
    // height: 90vh;
  }
}
@media (max-width: 1200px) {
  .corporation-intro-container {
    width: 80%;
    // height: 90vh;
  }
}
@media (max-width: 1000px) {
  .corporation-intro-container {
    width: 90%;
    // height: 90vh;
  }
  .description {
    width: 75%;
    margin-top: 40px;
    margin-left: 5%;
    height: 70vh;
  }
  .corporation-name {
    font-size: 30px;
  }
  .building-in-intro {
    display: none;
  }
  .scroll-container {
    margin-right: 10px;
  }
  #down_scroll {
    margin-top: 500px;
  }
}
.description-content {
  font-size: 20px;
}
</style>
<style>
.a-mod {
  color: wheat;
  text-decoration: none;
}
</style>
