<template>
  <div class="layout">
    <div v-if="showIntro1" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[0]"
        :building_image="building_images[0]"
        @click.native="isClickedInside"
      />
    </div>
    <div v-if="showIntro2" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[1]"
        :building_image="building_images[1]"
        @click.native="isClickedInside"
      />
    </div>
    <div v-if="showIntro3" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[2]"
        :building_image="building_images[2]"
        @click.native="isClickedInside"
      />
    </div>
    <div v-if="showIntro4" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[3]"
        :building_image="building_images[3]"
        @click.native="isClickedInside"
      />
    </div>
    <div v-if="showIntro5" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[4]"
        :building_image="building_images[4]"
        @click.native="isClickedInside"
      />
    </div>
    <div v-if="showIntro6" class="pop-up-corporation-intro" @click="handleClickOutside">
      <CorporationIntro
        @return="close"
        class="intro-section"
        :name="names[5]"
        :building_image="building_images[5]"
        @click.native="isClickedInside"
      />
    </div>
    <div class="airship">
      <Airship v-show="!intro_section_on" />
    </div>
    <div @click="close_outside" class="main-layout">
      <NavBar
        @toIntro="toIntro"
        @toJoinUs="toJoinUs"
        @toSchedule="toSchedule"
        @toStaff="toStaff"
        class="nav-bar"
        v-show="!intro_section_on"
      />
      <Buildings
        @show1="show1"
        @show2="show2"
        @show3="show3"
        @show4="show4"
        @show5="show5"
        @show6="show6"
        class="buildings"
        :class="{introOn: intro_section_on}"
        introOn="introOn"
        v-show="!intro_section_on"
      />
      <div class="RWD-buildings">
        <BuildingsV2
          @toIntro="toIntro"
          @toJoinUs="toJoinUs"
          @toSchedule="toSchedule"
          @toStaff="toStaff"
          @show1="show1"
          @show2="show2"
          @show3="show3"
          @show4="show4"
          @show5="show5"
          @show6="show6"
          @closeMenu="closeMenu"
          @openMenu="openMenu"
          v-show="!intro_section_on"
        />
      </div>
      <div v-show="!menu && !intro_section_on" class="intro">
        <Intro />
      </div>
      <div v-show="!menu && !intro_section_on" class="join-us-section">
        <JoinUs />
      </div>
      <div v-show="!menu && !intro_section_on" class="schedule">
        <Schedule @close_schedule="scroll_to_schedule" />
      </div>
      <div v-show="!menu && !intro_section_on" class="staffs-section">
        <Staffs />
      </div>
      <div v-show="!menu && !intro_section_on" class="sponsers">
        <Sponsers />
      </div>
      <div v-show="!menu && !intro_section_on" class="footer-section">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CorporationIntro from "../components/CorporationIntro.vue";
import NavBar from "../components/NavBar.vue";
import Buildings from "../components/Buildings.vue";
import Intro from "../components/Intro.vue";
import JoinUs from "../components/JoinUs.vue";
import Schedule from "../components/Schedule.vue";
import Staffs from "../components/Staffs.vue";
import Sponsers from "../components/Sponsers.vue";
import Footer from "../components/Footer.vue";
import Airship from "../components/Airship.vue";
import BuildingsV2 from "../components/BuildingsV2";
/* eslint-enable */
export default {
  name: "Layout",
  components: {
    NavBar,
    Buildings,
    Intro,
    JoinUs,
    Schedule,
    Staffs,
    Sponsers,
    Footer,
    CorporationIntro,
    Airship,
    BuildingsV2
  },
  data() {
    return {
      showIntro1: false,
      showIntro2: false,
      showIntro3: false,
      showIntro4: false,
      showIntro5: false,
      showIntro6: false,
      outside_div_is_clicked: false,
      intro_section_is_clicked: false,
      names: [
        "KKBOX",
        "羅技電子",
        "中華電信",
        "威盛電子",
        "富比庫",
        "新竹市政府"
      ],
      // eslint-disable-next-line
      building_images: [
        require("../materials/building_1_full.png"),
        require("../materials/building_2_full.png"),
        require("../materials/building_3_full.png"),
        require("../materials/building_4_full.png"),
        require("../materials/building_5_full.png"),
        require("../materials/building_6_full.png")
      ],
      menu: false,
      intro_section_on: false
    };
  },
  methods: {
    show1() {
      if (this.showIntro1 === false) {
        setTimeout(this.show1, 100);
      }
      this.showIntro1 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    show2() {
      if (this.showIntro2 === false) {
        setTimeout(this.show2, 100);
      }
      this.showIntro2 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    show3() {
      if (this.showIntro3 === false) {
        setTimeout(this.show3, 100);
      }
      this.showIntro3 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    show4() {
      if (this.showIntro4 === false) {
        setTimeout(this.show4, 100);
      }
      this.showIntro4 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    show5() {
      if (this.showIntro5 === false) {
        setTimeout(this.show5, 100);
      }
      this.showIntro5 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    show6() {
      if (this.showIntro6 === false) {
        setTimeout(this.show6, 100);
      }
      this.showIntro6 = true;
      this.intro_section_on = true;
      document.getElementsByClassName("main-layout")[0].style.opacity = "0.3";
    },
    close() {
      this.showIntro1 = false;
      this.showIntro2 = false;
      this.showIntro3 = false;
      this.showIntro4 = false;
      this.showIntro5 = false;
      this.showIntro6 = false;
      this.intro_section_on = false;
      document.getElementsByClassName("main-layout")[0].style.opacity = "1";
    },
    close_outside() {
      if (this.showIntro6) {
        this.close();
      } else if (this.showIntro5) {
        this.close();
      } else if (this.showIntro4) {
        this.close();
      } else if (this.showIntro3) {
        this.close();
      } else if (this.showIntro2) {
        this.close();
      } else if (this.showIntro1) {
        this.close();
      }
    },
    handleClick() {
      this.isClickedInside();
      this.handleClickOutside();
    },
    handleClickOutside() {
      if (this.intro_section_is_clicked === false) {
        this.close_outside();
      }
      this.intro_section_is_clicked = false;
    },
    isClickedInside() {
      this.intro_section_is_clicked = true;
    },
    toIntro() {
      if (this.menu === true) {
        setTimeout(this.toIntro, 100);
      }
      document
        .getElementsByClassName("intro")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    toJoinUs() {
      if (this.menu === true) {
        setTimeout(this.toJoinUs, 100);
      }
      document
        .getElementsByClassName("join-us-section")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    toSchedule() {
      if (this.menu === true) {
        setTimeout(this.toSchedule, 100);
      }
      document
        .getElementsByClassName("schedule")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    toStaff() {
      if (this.menu === true) {
        setTimeout(this.toStaff, 100);
      }
      document
        .getElementsByClassName("staffs-section")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    closeMenu() {
      this.menu = false;
    },
    openMenu() {
      this.menu = true;
    },
    scroll_to_schedule() {
      setTimeout(function() {
        document
          .getElementsByClassName("schedule")[0]
          .scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }
};
</script>

<style scoped>
.introOn {
  height: 100vh;
  overflow: hidden;
}
.pop-up-corporation-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 58px;
  left: 0;
  top: 0;
  z-index: 5;
}
.intro {
  background: url("../materials/question_mark.svg");
  background-position-y: 20%;
  background-position-x: -60%;
  background-repeat: no-repeat;
  background-size: 800px;
  margin-top: 650px;
}
.join-us-section {
  background: url("../materials/pencil_bg.png");
  background-repeat: no-repeat;
  background-position-y: 20%;
  background-size: 800px;
}
.schedule {
  background: url("../materials/schedule_bg.png");
  background-position-y: 5%;
  background-repeat: no-repeat;
  padding-bottom: 500px;
  background-size: 800px;
}
.staffs-section {
  background: url("../materials/Staffs_bg.png");
  background-position-y: 20%;
  background-repeat: no-repeat;
  background-size: 800px;
}
.sponsers {
  background: url("../materials/Sponsers_bg.png");
  background-position-y: 30%;
  background-repeat: no-repeat;
  background-size: 1000px;
}
@media (min-width: 1024px) {
  .RWD-buildings {
    display: none;
  }
  .intro {
    background-position-x: -10%;
  }
  .join-us-section {
    background-position-x: 110%;
  }
  .schedule {
    background-position-x: -20%;
  }
  .staffs-section {
    background-position-x: 110%;
  }
  .sponsers {
    background-position-x: -35%;
  }
}
@media (max-width: 1024px) {
  .airship {
    display: none;
  }
  .nav-bar {
    display: none;
  }
  .buildings {
    display: none;
  }
  .intro {
    background-size: 600px;
    background-position-x: -150px;
    margin-top: -120px;
  }
  .join-us-section {
    background-size: 600px;
    background-position-x: 120%;
    margin-top: -150px;
  }
  .schedule {
    background-size: 700px;
    background-position-x: -120px;
    margin-top: -120px;
  }
  .staffs-section {
    background-size: 650px;
    background-position-x: 450px;
    margin-top: -350px;
  }
  .sponsers {
    background-position-x: -200px;
    margin-top: -80px;
  }
}
@media (max-width: 900px) {
  .sponsers {
    background-size: 750px;
    background-position-x: 200px;
  }
  .join-us-section {
    background-position-x: 150%;
  }
  .staffs-section {
    background-position-x: 250px;
  }
  .sponsers {
    background-position-x: -150px;
  }
}
@media (max-width: 650px) {
  .schedule {
    background-position-x: -150px;
  }
  .sponsers {
    background-position-x: -90px;
    background-size: 600px;
  }
  .join-us-section {
    background-size: 600px;
    background-position-x: 80px;
  }
  .staffs-section {
    background-size: 600px;
    background-position-x: 50px;
  }
}
@media (max-width: 470px) {
  .join-us-section {
    background-size: 500px;
    background-position-x: 50px;
  }
}
</style>
