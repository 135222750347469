<template>
  <div class="contest-info-container">
    <div class="contest-info">
      <div class="subtitle">比賽資訊</div>
      <div class="content">
        <b>比賽時間</b>：10/26 (六)、10/27 (日) 兩天一夜
        <br />
        <br />
        <b>比賽地點</b>：國立清華大學新體育館
        <br />
        <br />
        <b>分組報名</b>：比賽將分為創客組與黑客組
        <br />
        <br />創客組：由新竹市政府作為引導單位，最終將選出前三名作為獲勝隊伍。
        <br />黑客組：由五家企業作為引導單位，初賽時由各企業選出前三名獲得企業獎。企業獎第一名進入決賽，決賽時再由五隊中選出前兩名作為獲勝隊伍。
      </div>
      <div class="subtitle">獎項</div>
      <div class="content">
        創客組
        <br />第一名新台幣拾萬元整
        <br />第二名新台幣捌萬元整
        <br />第三名新台幣伍萬元整
        <br />
        <br />黑客組初賽
        <br />第一名：新台幣壹萬元整+合作單位實體獎品+實習資格
        <br />第二名：新台幣伍仟元整+合作企業實體獎品
        <br />第三名：新台幣貳仟元整+合作企業實體獎品
        <br />
        <br />梅竹大獎
        <br />第一名：新台幣貳萬元整
        <br />第二名：新台幣壹萬元整
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContestInfo"
};
</script>

<style scoped lang="scss">
.contest-info-container {
  border: 6px solid #707070;
  width: 65.7%;
  color: #b1b1b1;
  margin-top: 159px;
  margin-right: 16%;
  padding: 0px 10% 30px 10%;
}
.contest-info {
  width: 79%;
  margin: 66px 13% 50.5px 13%;
  .subtitle {
    font-size: 50px;
    margin-top: 66px;
    margin-bottom: 50px;
  }
  .content {
    font-size: 20px;
  }
}
@media (max-width: 1248px) {
  .contest-info-container {
    width: 90%;
    margin-right: 10%;
    padding: 0px 1% 10px 1%;
  }
  .contest-info {
    margin: 20px 10% 20px 10%;
    .subtitle {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .content {
      font-size: 20px;
    }
  }
}
@media (max-width: 790px) {
  .contest-info {
    .subtitle {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .content {
      font-size: 20px;
    }
  }
}
</style>
