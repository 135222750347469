import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  components: { App },
  template: '<App />',
  render: h => h(App),
  mounted() {
    // document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');
