<template>
  <div>
    <div class="group-title-container">
      <div class="group-title">{{ group }}</div>
      <div class="line">
        <img src="../materials/department_line.png" alt />
      </div>
    </div>
    <div class="corporation-container">
      <div v-for="index in number" :key="index" class="corporation-item">
        <div>
          <img :src="images[index-1]" class="logo" />
        </div>
        <div class="name">{{ names[index-1] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Corporation",
  props: ["group", "images", "names", "number"]
};
</script>

<style lang="scss" scoped>
.group-title-container {
  display: flex;
  margin-left: 19%;
}
.group-title {
  font-size: 40px;
  color: #b1b1b1;
  margin-right: 2.5%;
  margin-bottom: 51px;
}
.corporation-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 41.7px;
  margin-left: 17%;
  .corporation-item {
    margin-left: 10%;
    margin-bottom: 8px;
  }
  .name {
    margin-top: 30px;
    font-size: 21px;
    color: #b1b1b1;
  }
}
@media (max-width: 700px) {
  .group-title {
    font-size: 30px;
  }
  .corporation-container {
    .name {
      font-size: 16px;
    }
  }
  .logo {
    width: 80px;
  }
  .line {
    margin-top: -15px;
  }
}
.logo {
  max-width: 200px;
}
</style>>
