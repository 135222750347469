<template>
  <div class="details-container">
    <div class="times-container">
      <div class="date-title">
        <u>Day 02</u>
      </div>
      <div class="schedule-details-container">
        <div class="schedules">
          <div>
            07:00-08:00
            <br />早餐
            <br />
          </div>
          <div>
            08:00-11:00
            <br />最後衝刺
            <br />
          </div>
          <div>
            11:00-12:00
            <br />午餐
            <br />
          </div>
          <div>
            12:00-13:00
            <br />最後衝刺
            <br />
          </div>
          <div>
            13:00-14:40
            <br />黑客組初賽+
            <br />創客組競賽
          </div>
        </div>
        <div class="schedules">
          <div>
            14:40-15:00
            <br />評審討論
          </div>
          <div>
            15:00-16:30
            <br />黑客組決賽
          </div>
          <div>
            16:30-17:55
            <br />頒獎
          </div>
          <div>
            17:55-18:30
            <br />抽獎活動
          </div>
          <div>
            18:30-19:00
            <br />閉幕式
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-side-container">
      <div class="schedule_icon">
        <img src="../materials/schedule_icon.png" />
      </div>
      <div>Day 02</div>
      <div>10/27</div>
      <div class="under_line" @click="returnToNormal">
        <img src="../materials/return.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleDetails2",
  methods: {
    returnToNormal() {
      this.$emit("return");
      var box = document.getElementsByClassName("schedule-side-container")[0];
      box.style.animation = "none";
      box.offsetHeight;
      box.style.animation = null;
      box.style.animationDirection = "reverse";
      document.getElementsByClassName("under_line")[0].style.display = "none";
      var details = document.getElementsByClassName("times-container")[0];
      details.style.animation = "none";
      details.offsetHeight;
      details.style.animation = null;
      details.style.animationDirection = "reverse";
    }
  }
};
</script>

<style scoped lang="scss">
.schedule_icon {
  margin-top: 45px;
}
.details-container {
  padding-bottom: 800px;
}
.schedule-side-container {
  text-align: center;
  font-size: 30px;
  color: #b1b1b1;
  border: 6px solid #707070;
  width: 17.7%;
  animation-name: box;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: absolute;
  right: 10.3%;
}
@keyframes box {
  from {
    height: 478px;
  }
  to {
    height: 771px;
  }
}
.under_line {
  animation-name: under_line;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  cursor: pointer;
}
@keyframes under_line {
  from {
    margin-bottom: 70px;
  }
  to {
    margin-top: 424.5px;
  }
}
.times-container {
  width: 55%;
  animation-name: schedules;
  animation-duration: 1s;
  // animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: absolute;
}
@keyframes schedules {
  0% {
    right: 120%;
  }
  100% {
    right: 35%;
  }
}
.date-title {
  font-family: Apple Chancery, cursive;
  font-size: 80px;
  color: #ffffff;
  margin-left: 8%;
  margin-bottom: 85px;
}
.schedule-details-container {
  display: flex;
  justify-content: space-around;
}
.schedules {
  font-size: 30px;
  color: #ffffff;
  margin: 30px;
  text-align: center;
}
.schedules > div {
  margin-bottom: 75px;
}
@media (max-width: 1248px) {
  .schedule-side-container {
    width: 19.1%;
    right: 7.5%;
  }
}
@media (max-width: 850px) {
  .schedules {
    font-size: 25px;
  }
  .schedule-side-container {
    font-size: 25px;
    width: 23%;
    margin-right: -5%;
  }
}
@media (max-width: 677px) {
  .schedules {
    font-size: 20px;
  }
  .date-title {
    font-size: 50px;
  }
  .schedule-side-container {
    font-size: 25px;
    width: 23%;
    margin-right: -5%;
    .schedule_icon {
      img {
        width: 60%;
      }
    }
  }
}
@media (max-width: 500px) {
  .schedules {
    font-size: 15px;
  }
  .date-title {
    font-size: 45px;
  }
  .schedule-side-container {
    font-size: 20px;
  }
}
</style>
