<template>
  <div class="schedule-side-container">
    <div class="icon">
      <img src="../materials/schedule_icon.png" />
    </div>
    <div>{{ day }}</div>
    <div class="date">{{ date }}</div>
  </div>
</template>

<script>
export default {
  name: "ScheduleSide",
  props: ["day", "date"]
};
</script>

<style scoped lang="scss">
.schedule-side-container {
  text-align: center;
  font-size: 30px;
  color: #b1b1b1;
  border: 6px solid #707070; 
  img {
    margin-top: 54.5px;
    margin-bottom: 39.2px;
  }
  .date {
    margin-bottom: 201.7px;
  }
}
@media (max-width: 800px) {
  .schedule-side-container {
    img {
      width: 90px;
    }
  }
}
@media (max-width: 681px) {
  .schedule-side-container {
    img {
      width: 80px;
    }
    .date {
      margin-bottom: 130.7px;
    }
  }
}
@media (max-width: 500px) {
  .schedule-side-container {
    margin-right: 10%;
    margin-left: 10%;
    font-size: 25px;
    img {
      width: 60px;
    }
    .date {
      margin-bottom: 80.7px;
    }
  }
}
</style>
