<template>
  <div class="join-us-section">
    <div class="join-us-title">報名資訊 / Join us</div>
    <div class="register-info">
      <RegisterInfo />
    </div>
    <div class="contest-info">
      <ContestInfo />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import RegisterInfo from "../components/RegisterInfo.vue";
// eslint-disable-next-line
import ContestInfo from "../components/ContestInfo.vue";

export default {
  name: "JoinUs",
  components: {
    RegisterInfo,
    ContestInfo
  }
};
</script>

<style scoped>
.join-us-title {
  font-size: 50px;
  color: #b1b1b1;
  margin-left: 13%;
  margin-top: 267px;
  margin-bottom: 78px;
}
.register-info {
  margin-left: 13%;
}
.contest-info {
  margin-left: 24%;
}
@media (max-width: 1248px) {
  .register-info {
    margin-left: 5%;
  }
  .contest-info {
    margin-left: 15%;
  }
}
@media (max-width: 500px) {
  .join-us-title {
    font-size: 40px;
    margin-left: 5%;
  }
}
</style>
