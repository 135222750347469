<template>
  <div class="schedule-section">
    <div class="schedule-title">活動日程 / Schedule</div>
    <div class="schedule-container" v-if="!is_clicked1 && !is_clicked2">
      <div class="side-box">
        <ScheduleSide
          :day="day_1"
          :date="date_1"
          @click.native="show_schedule"
          class="schedule-side"
        />
      </div>
      <ScheduleCenter />
      <div class="side-box">
        <ScheduleSide
          :day="day_2"
          :date="date_2"
          @click.native="show_schedule2"
          class="schedule-side"
        />
      </div>
    </div>
    <!-- pop up details -->
    <div v-if="is_clicked1" class="schedule-details-section">
      <ScheduleDetails @return="close_schedule" />
    </div>
    <div v-if="is_clicked2" class="schedule-details-section">
      <ScheduleDetails2 @return="close_schedule2" />
    </div>
    <div class="rwd-details">
      <ScheduleDetails1RWD />
      <ScheduleDetails2RWD />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import ScheduleCenter from "../components/ScheduleCenter.vue";
// eslint-disable-next-line
import ScheduleSide from "../components/ScheduleSide.vue";
// eslint-disable-next-line
import ScheduleDetails from "../components/ScheduleDetails.vue";
// eslint-disable-next-line
import ScheduleDetails2 from "../components/ScheduleDetails2.vue";
import ScheduleDetails1RWD from "../components/ScheduleDetails1RWD";
import ScheduleDetails2RWD from "../components/ScheduleDetails2RWD";

export default {
  name: "Schedule",
  components: {
    ScheduleCenter,
    ScheduleSide,
    ScheduleDetails,
    ScheduleDetails2,
    ScheduleDetails1RWD,
    ScheduleDetails2RWD
  },
  data() {
    return {
      day_1: "Day 01",
      date_1: "10/26",
      day_2: "Day 02",
      date_2: "10/27",
      is_clicked1: false,
      is_clicked2: false
    };
  },
  methods: {
    show_schedule() {
      this.is_clicked1 = true;
    },
    show_schedule2() {
      this.is_clicked2 = true;
    },
    close_schedule() {
      setTimeout(() => (this.is_clicked1 = false), 1000);
      this.$emit("close_schedule");
    },
    close_schedule2() {
      setTimeout(() => (this.is_clicked2 = false), 1000);
      this.$emit("close_schedule");
    }
  }
};
</script>

<style scoped lang="scss">
.rwd-details {
  display: none;
}
.schedule-title {
  font-size: 50px;
  color: #b1b1b1;
  margin-left: 13%;
  margin-top: 293px;
  margin-bottom: 78px;
}
.schedule-container {
  display: flex;
  justify-content: space-between;
  width: 79.4%;
  margin: 99.5px 10.3% 0 10.3%;
  .side-box {
    width: 23.3%;
  }
  .schedule-side {
    cursor: pointer;
  }
}
.schedule-details-section {
  margin-top: 100px;
}
@media (max-width: 1248px) {
  .schedule-container {
    width: 85%;
    margin: 99.5px 7.5% 0 7.5%;
  }
}
@media (max-width: 1100px) {
  .schedule-container {
    .side-box {
      width: 40%;
    }
  }
}
@media (max-width: 700px) {
  .schedule-container {
    justify-content: space-around;
  }
}
@media (max-width: 500px) {
  .schedule-title {
    font-size: 40px;
    margin-left: 5%;
  }
  .schedule-container {
    width: 96%;
    margin-left: 2%;
  }
}
@media (max-width: 600px) {
  .rwd-details {
    display: block;
  }
  .schedule-container {
    display: none;
  }
}
</style>
