<template>
  <div class="buildings-section" @click="close_outside">
    <!-- down arrows -->
    <div class="flex-container arrows">
      <div class="kkbox-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle1" />
        <br v-else />
        <div v-if="showTriangle1">{{ company[0] }}</div>
        <br v-else />
      </div>
      <div class="logitech-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle2" />
        <div v-if="showTriangle2">{{ company[1] }}</div>
      </div>
      <div class="cht-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle3" />
        <div v-if="showTriangle3">{{ company[2] }}</div>
      </div>
      <div class="via-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle4" />
        <div v-if="showTriangle4">{{ company[3] }}</div>
      </div>
      <div class="footprintku-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle5" />
        <div v-if="showTriangle5">{{ company[4] }}</div>
      </div>
      <div class="hccg-name">
        <img src="../materials/triangle_down.png" v-if="showTriangle6" />
        <div v-if="showTriangle6">{{ company[5] }}</div>
      </div>
    </div>
    <!-- corporation names -->
    <div class="name flex-container">
      <!-- <div v-for="value in 6" :key="value">{{ company[value-1] }}</div> -->
      <!-- <div class="kkbox-name">{{ company[0] }}</div>
      <div class="logitech-name">{{ company[1] }}</div>
      <div class="cht-name">{{ company[2] }}</div>
      <div class="via-name">{{ company[3] }}</div>
      <div class="footprintku-name">{{ company[4] }}</div>
      <div class="hccg-name">{{ company[5] }}</div>-->
    </div>
    <!-- buildings -->
    <div class="fb_icon" v-show="!introOn">
      <a href="https://www.facebook.com/HackMeiChu/" target="_blank">
        <img src="../materials/fb_icon_big.png" />
      </a>
    </div>
    <div class="flex-container main-buildings">
      <div
        class="building-click"
        @click="show(1)"
        @mouseover="showTriangle1=true"
        @mouseout="showTriangle1=false"
      >
        <img src="../materials/building_1.png" class="building building1" />
      </div>
      <div
        class="building-click"
        @click="show(2)"
        @mouseover="showTriangle2=true"
        @mouseout="showTriangle2=false"
      >
        <img
          src="../materials/building_2.png"
          class="building building2"
          @mouseover="showTriangle2=true"
          @mouseout="showTriangle2=false"
        />
      </div>
      <div
        class="building-click"
        @click="show(3)"
        @mouseover="showTriangle3=true"
        @mouseout="showTriangle3=false"
      >
        <img
          src="../materials/building_3.png"
          class="building building3"
          @mouseover="showTriangle3=true"
          @mouseout="showTriangle3=false"
        />
      </div>
      <div
        class="building-click"
        @click="show(4)"
        @mouseover="showTriangle4=true"
        @mouseout="showTriangle4=false"
      >
        <img
          src="../materials/building_4.png"
          class="building building4"
          @mouseover="showTriangle4=true"
          @mouseout="showTriangle4=false"
        />
      </div>
      <div
        class="building-click"
        @click="show(5)"
        @mouseover="showTriangle5=true"
        @mouseout="showTriangle5=false"
      >
        <img
          src="../materials/building_5.png"
          class="building building5"
          @mouseover="showTriangle5=true"
          @mouseout="showTriangle5=false"
        />
      </div>
      <div
        class="building-click"
        @click="show(6)"
        @mouseover="showTriangle6=true"
        @mouseout="showTriangle6=false"
      >
        <img
          src="../materials/building_6.png"
          class="building building6"
          @mouseover="showTriangle6=true"
          @mouseout="showTriangle6=false"
        />
      </div>
    </div>
    <div class="glow-container">
      <div class="glow">
        <img src="../materials/building_1_glow.png" class="glow1" />
      </div>
      <div class="glow">
        <img src="../materials/building_2_glow.png" class="glow2" />
      </div>
      <div class="glow">
        <img src="../materials/building_3_glow.png" class="glow3" />
      </div>
      <div class="glow">
        <img src="../materials/building_4_glow.png" class="glow4" />
      </div>
      <div class="glow">
        <img src="../materials/building_5_glow.png" class="glow5" />
      </div>
      <div class="glow">
        <img src="../materials/building_6_glow.png" class="glow6" />
      </div>
    </div>
    <div class="logos-container">
      <div @click="show(1)">
        <img src="../images/rect/kkbox.png" class="log kkbox" />
      </div>
      <div @click="show(2)">
        <img src="../images/rect/logitech.png" class="logo logitech" />
      </div>
      <div class="adjust" @click="show(3)">
        <img src="../images/rect/cht-rect.png" class="logo cht" />
      </div>
      <div @click="show(4)">
        <img src="../images/rect/ia.png" class="logo via" />
      </div>
      <div class="adjust" @click="show(5)">
        <img src="../images/rect/footprintku.png" class="logo footprintku" />
      </div>
      <div @click="show(6)">
        <img src="../images/rect/hccg.png" class="logo hccg" />
      </div>
    </div>
  </div>
</template>

<script scoped>
// eslint-disable-next-line
import CorporationIntro from "../components/CorporationIntro.vue";
export default {
  name: "Buildings",
  props: ["introOn"],
  data() {
    return {
      showTriangle1: false,
      showTriangle2: false,
      showTriangle3: false,
      showTriangle4: false,
      showTriangle5: false,
      showTriangle6: false,
      showIntro1: false,
      showIntro2: false,
      showIntro3: false,
      showIntro4: false,
      showIntro5: false,
      showIntro6: false,
      company: [
        "KKBOX",
        "羅技電子",
        "中華電信",
        "威盛電子",
        "富比庫",
        "新竹市政府"
      ]
    };
  },
  methods: {
    // test() {
    //   console.log('click');
    // },
    show(id) {
      this.$emit("show" + id);
      // document.getElementsByClassName("layout")[0].scrollIntoView();
      // document.body.style["overflow-y"] = "hidden";
    },
    close_outside() {
      if (this.showIntro6) {
        this.showIntro6 = false;
        // document.body.style["overflow-y"] = "auto";
      } else if (this.showIntro5) {
        this.showIntro5 = false;
        // document.body.style["overflow-y"] = "auto";
      } else if (this.showIntro4) {
        this.showIntro5 = false;
        // document.body.style["overflow-y"] = "auto";
      } else if (this.showIntro3) {
        this.showIntro5 = false;
        // document.body.style["overflow-y"] = "auto";
      } else if (this.showIntro2) {
        this.showIntro5 = false;
        // document.body.style["overflow-y"] = "auto";
      } else if (this.showIntro1) {
        this.showIntro5 = false;
        // document.body.style["overflow-y"] = "auto";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.buildings-section {
  height: 800px;
  background-image: url("../materials/bg_1.png");
  background-position: 0px 520px;
  background-size: 1200px;
  background-repeat: repeat-x;
  background-repeat: repeat-x;
  background-image: url("../materials/bg_1.png"), url("../materials/bg_2.png"),
    url("../materials/bg_3.png");
  background-size: 1200px;
  background-position: 0px 520px, 0 470px, 0 470px;
}
.fb_icon {
  position: absolute;
  top: 830px;
  left: 90%;
  z-index: 10;
}
.name {
  margin-bottom: 70px;
  margin-top: 10px;
}
.flex-container {
  display: flex;
  justify-content: space-around;
  color: white;
  text-align: center;
  align-items: flex-end;
}
.arrows {
  margin-top: 75px;
  display: flex;
  justify-content: space-around;
  > div {
    width: 100px;
  }
  .kkbox-name {
    margin-left: -0.8%;
  }
  .cht-name {
    margin-left: 2%;
  }
  .footprintku-name {
    margin-right: -1%;
  }
}
.building {
  z-index: -1;
  cursor: pointer;
  position: relative;
}
.building1,
.glow1 {
  width: 133.06px;
}
.building2,
.glow2 {
  width: 172.08px;
}
.building3,
.glow3 {
  width: 140.63px;
}
.glow3 {
  position: relative;
  top: 6px;
}
.building4,
.glow4 {
  width: 152.77px;
}
.building5,
.glow5 {
  width: 145.48px;
}
.building6,
.glow6 {
  width: 124.82px;
}
.glow6 {
  position: relative;
  top: 6px;
}
.glow-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: flex-end;
  pointer-events: none;
  top: -576px;
  z-index: -1;
  .glow {
    animation-name: glow;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1s;
  }
}
@keyframes glow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.logos-container {
  display: flex;
  position: relative;
  top: -1200px;
  z-index: 5;
  pointer-events: none;
  justify-content: space-around;
  animation-name: logos;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  pointer-events: auto;
  div {
    cursor: pointer;
    .kkbox {
      width: 100px;
      margin-left: 10%;
    }
    .logitech {
      width: 150px;
      margin-left: 20%;
    }
    .cht {
      width: 120px;
      margin-left: 0;
    }
    .via {
      width: 80px;
      margin-left: 45%;
    }
    .footprintku {
      width: 140px;
      margin-left: 17%;
    }
    .hccg {
      width: 140px;
    }
  }
  .adjust {
    margin-left: 3%;
  }
}
@keyframes logos {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
.building-click {
  cursor: pointer;
}
</style>
