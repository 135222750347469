<template>
  <div>
    <div class="RWD-building" @click="clickOutside">
      <div class="top-bar">
        <div class="menu" @click="handleClick">
          <img src="../materials/menu.png" />
        </div>
        <div class="meichu-logo">
          <img src="../materials/RWD_logo.png" />
        </div>
      </div>
      <div class="building">
        <div class="building-img">
          <img src="../materials/RWD_building.png" />
        </div>
      </div>
      <div class="building-container">
        <div class="names">
          <dir class="name-container" @click="show(1)">
            <div>
              <img src="../materials/left_tri.png" />
            </div>
            <div class="name">KKBOX</div>
          </dir>
          <dir class="name-container" @click="show(3)">
            <div>
              <img src="../materials/left_tri.png" />
            </div>
            <div class="name">中華電信</div>
          </dir>
          <dir class="name-container" @click="show(5)">
            <div>
              <img src="../materials/left_tri.png" />
            </div>
            <div class="name">富比庫</div>
          </dir>
        </div>
        <div class="logos-container">
          <div class="logos">
            <div class="logo first" @click="show(1)">
              <img class="img-logo kkbox-logo" src="../images/square/kkbox.png" />
            </div>
            <div class="logo second" @click="show(3)">
              <img class="img-logo cht-logo" src="../images/square/cht.png" />
            </div>
            <div class="logo third" @click="show(5)">
              <img class="img-logo footprintku-logo" src="../images/square/footprintku.png" />
            </div>
          </div>
          <div class="logos">
            <div class="logo first" @click="show(2)">
              <img class="img-logo logitech-logo" src="../images/square/logitech.png" />
            </div>
            <div class="logo second" @click="show(4)">
              <img class="img-logo via-logo" src="../images/square/via.png" />
            </div>
            <div class="logo third" @click="show(6)">
              <img class="img-logo hccg-logo" src="../images/square/hccg.png" />
            </div>
          </div>
        </div>
        <div class="names">
          <dir class="name-container" @click="show(2)">
            <div>
              <img src="../materials/right_tri.png" />
            </div>
            <div class="name">羅技電子</div>
          </dir>
          <dir class="name-container" @click="show(4)">
            <div>
              <img src="../materials/right_tri.png" />
            </div>
            <div class="name">威盛電子</div>
          </dir>
          <dir class="name-container" @click="show(6)">
            <div>
              <img src="../materials/right_tri.png" />
            </div>
            <div class="name">新竹市政府</div>
          </dir>
        </div>
      </div>
    </div>
    <div v-show="is_clicked" class="pop-up-section">
      <Menu @toIntro="toIntro" @toJoinUs="toJoinUs" @toSchedule="toSchedule" @toStaff="toStaff" />
    </div>
  </div>
</template>

<script>
import Menu from "./RWD_menu.vue";

export default {
  name: "BuildingsV2",
  components: {
    Menu
  },
  data() {
    return {
      is_clicked: false,
      showIntro1: false,
      showIntro2: false,
      showIntro3: false,
      showIntro4: false,
      showIntro5: false,
      showIntro6: false
    };
  },
  methods: {
    handleClick() {
      if (this.is_clicked === false) {
        setTimeout(this.handleClick, 100);
      }
      this.is_clicked = true;
      this.$emit("openMenu");
      // document.getElementsByClassName("RWD-building")[0].style.opacity = "0.3";
      // document.getElementsByClassName("layout")[0].scrollIntoView();
      // document.body.style["overflow-y"] = "hidden";
      // var h = Math.max(
      // document.documentElement.clientHeight,
      // window.innerHeight || 0
      // );
      /*document.getElementsByClassName("corporation-intro-container")[0].style[
        "height"
      ] = h - 80;*/
    },
    clickOutside() {
      // document.getElementsByClassName("RWD-building")[0].style.opacity = "1";
      // document.body.style["overflow-y"] = "auto";
      if (this.is_clicked) {
        this.close();
      }
    },
    close() {
      this.is_clicked = false;
      this.$emit("closeMenu");
    },
    toIntro() {
      this.$emit("toIntro");
      this.close();
    },
    toJoinUs() {
      this.$emit("toJoinUs");
      this.close();
    },
    toSchedule() {
      this.$emit("toSchedule");
      this.close();
    },
    toStaff() {
      this.$emit("toStaff");
      this.close();
    },
    show(id) {
      this.$emit("show" + id);
      // document.getElementsByClassName("layout")[0].scrollIntoView();
      // document.body.style["overflow-y"] = "hidden";
    },
    close_outside() {
      // document.body.style["overflow-y"] = "auto";
      if (this.showIntro6) {
        this.showIntro6 = false;
      } else if (this.showIntro5) {
        this.showIntro5 = false;
      } else if (this.showIntro4) {
        this.showIntro5 = false;
      } else if (this.showIntro3) {
        this.showIntro5 = false;
      } else if (this.showIntro2) {
        this.showIntro5 = false;
      } else if (this.showIntro1) {
        this.showIntro5 = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.RWD-building {
  background: url("../materials/bg_1.png");
  background-size: 900px;
  background-repeat: repeat-x;
  background-position: 0px 600px;
}
.top-bar {
  height: 85px;
  .menu {
    position: absolute;
    top: 31.5px;
    left: 6%;
    cursor: pointer;
  }
  .meichu-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      height: 61.47px;
      margin-top: 24.7px;
    }
  }
}
.building-container {
  margin-top: -600px;
  display: flex;
  height: 630px;
  justify-content: space-around;
  .names {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    .name-container {
      text-align: center;
      color: #b1b1b1;
      font-size: 15px;
      font-weight: bold;
      padding: 0;
      cursor: pointer;
      .name {
        margin-top: 15px;
        margin-bottom: 60px;
      }
    }
  }
}
.building {
  display: flex;
  justify-content: center;
  align-items: center;
  .building-img {
    z-index: -1;
    > img {
      height: 650px;
      width: auto;
    }
  }
}
.logos-container {
  display: flex;
  justify-content: space-between;
  width: 110px;
  margin-right: 35px;
  .logos {
    .logo {
      cursor: pointer;
      animation-name: logo;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1s;
      margin-bottom: 50px;
      height: 140px;
      width: 75px;
      margin-right: 5px;
      text-align: center;
      .img-logo {
        margin-top: 45px;
        width: 75px;
      }
      .logitech-logo {
        margin-left: 10px;
      }
      .kkbox-logo {
        margin-top: 65px;
        margin-left: 5px;
      }
      .cht-logo {
        margin-left: 7px;
      }
      .via-logo {
        margin-top: 60px;
      }
      .footprintku-logo {
        margin-top: 65px;
      }
      .hccg-logo {
        margin-top: 40px;
      }
    }
    .first {
      margin-top: 40px;
    }
    .second {
      margin-top: -33px;
    }
    .third {
      margin-top: -33px;
    }
  }
}
@keyframes logo {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
.pop-up-section {
  animation-name: menu;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  position: absolute;
  width: 70%;
  top: 0;
  height: 100%;
  padding-bottom: 25px;
  background-color: #112329;
}
@keyframes menu {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
.logo .img-logo {
  max-width: 60px;
}
</style>
