<template>
  <div class="nav-bar-item">
    <div class="english-title">{{ title_en }}</div>
    <hr />
    <div class="chinese-title">{{ title_ch }}</div>
    <div class="chinese-title">{{ title_ch2 }}</div>
  </div>
</template>

<script>
export default {
  name: "NavBarItem",
  props: ["title_en", "title_ch", "title_ch2"]
};
</script>

<style lang="scss" scoped>
hr {
  width: 20px;
  border: 1px solid #599fa4;
}
.nav-bar-item {
  text-align: center;
  font-weight: bold;
  color: #599fa4;
  cursor: pointer;
  .chinese-title {
    font-size: 20px;
  }
  .english-title {
    font-size: 15px;
  }
}
</style>
