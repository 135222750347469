<template>
  <div class="times-container">
    <div class="date-title">
      <u>Day 02 (10/27)</u>
    </div>
    <div class="schedule-details-container">
      <div class="schedules">
        <div>
          07:00-08:00
          <br />早餐
          <br />
        </div>
        <div>
          08:00-11:00
          <br />最後衝刺
          <br />
        </div>
        <div>
          11:00-12:00
          <br />午餐
          <br />
        </div>
        <div>
          12:00-13:00
          <br />最後衝刺
          <br />
        </div>
        <div>
          13:00-14:40
          <br />黑客組初賽+
          <br />創客組競賽
        </div>
      </div>
      <div class="schedules">
        <div>
          14:40-15:00
          <br />評審討論
        </div>
        <div>
          15:00-16:30
          <br />黑客組決賽
        </div>
        <div>
          16:30-17:55
          <br />頒獎
        </div>
        <div>
          17:55-18:30
          <br />抽獎活動
        </div>
        <div>
          18:30-19:00
          <br />閉幕式
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleDetails2RWD"
};
</script>

<style scoped lang="scss">
.times-container-rwd {
  margin-bottom: 40px;
  width: 55%;
  animation-name: schedules;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.date-title {
  font-family: Apple Chancery, cursive;
  font-size: 35px;
  color: #ffffff;
  margin-left: 3%;
  margin-bottom: 85px;
}
.schedule-details-container {
  display: flex;
  justify-content: space-around;
  margin-left: 10%;
}
.schedules {
  font-size: 20px;
  margin: -60px 30px 0px 30px;
  color: #b1b1b1;
}
.schedules > div {
  margin-bottom: 75px;
  text-align: center;
}
</style>