<template>
  <div class="details-container">
    <div class="schedule-side-container">
      <div class="schedule_icon">
        <img src="../materials/schedule_icon.png" />
      </div>
      <div>Day 01</div>
      <div>10/26</div>
      <div class="under_line" @click="returnToNormal">
        <img src="../materials/return.png" />
      </div>
    </div>
    <div class="times-container">
      <div class="date-title">
        <u>Day 01</u>
      </div>
      <div class="schedule-details-container">
        <div class="schedules">
          <div>
            08:30-09:00
            <br />參賽者報到
          </div>
          <div>
            09:00-09:30
            <br />開幕致詞&amp;
            <br />競賽說明
          </div>
          <div>
            09:30-10:00
            <br />企業題目講解
          </div>
          <div>
            10:30-12:00
            <br />開始 Coding
          </div>
        </div>
        <div class="schedules">
          <div>
            12:00-13:30
            <br />午餐
          </div>
          <div>
            13:30-18:00
            <br />持續 Coding
          </div>
          <div>
            18:00-19:30
            <br />晚餐與交流
          </div>
          <div>
            19:30
            <br />戰鬥永無止境
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleDetails",
  methods: {
    returnToNormal() {
      this.$emit("return");
      var box = document.getElementsByClassName("schedule-side-container")[0];
      box.style.animation = "none";
      box.offsetHeight;
      box.style.animation = null;
      box.style.animationDirection = "reverse";
      document.getElementsByClassName("under_line")[0].style.display = "none";
      var details = document.getElementsByClassName("times-container")[0];
      details.style.animation = "none";
      details.offsetHeight;
      details.style.animation = null;
      details.style.animationDirection = "reverse";
    }
  }
};
</script>

<style scoped lang="scss">
.schedule_icon {
  margin-top: 45px;
}
.details-container {
  padding-bottom: 800px;
  width: 100%;
}
.schedule-side-container {
  text-align: center;
  font-size: 30px;
  color: #b1b1b1;
  border: 6px solid #707070;
  width: 17.7%;
  animation-name: box;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: absolute;
  left: 10.3%;
}
@keyframes box {
  from {
    height: 478px;
  }
  to {
    height: 771px;
  }
}
.under_line {
  animation-name: under_line;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  cursor: pointer;
  -webkit-animation-name: under_line;
  -webkit-animation-duration: 0.75s;
  -webkit-animation-fill-mode: forwards;
}
@keyframes under_line {
  from {
    margin-bottom: 70px;
  }
  to {
    margin-top: 424.5px;
  }
}
.times-container {
  width: 55%;
  animation-name: schedules;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  // -webkit-animation-name: schedules;
  // -webkit-animation-duration: 1s;
  // -webkit-animation-timing-function: linear;
  // -webkit-animation-fill-mode: forwards;
  position: absolute;
}
@keyframes schedules {
  0% {
    left: 120%;
  }
  100% {
    left: 38%;
  }
}
.date-title {
  font-family: Apple Chancery, cursive;
  font-size: 80px;
  color: #ffffff;
  margin-left: 8%;
  margin-bottom: 85px;
}
.schedule-details-container {
  display: flex;
  justify-content: space-around;
}
.schedules {
  font-size: 30px;
  margin-right: 30px;
  color: #ffffff;
  text-align: center;
}
.schedules > div {
  margin-bottom: 75px;
  // height: 50px;
}
@media (max-width: 1248px) {
  .schedule-side-container {
    width: 18.5%;
    left: 7.5%;
  }
}
@media (max-width: 850px) {
  .schedules {
    font-size: 25px;
  }
  .schedule-side-container {
    font-size: 25px;
    width: 23%;
    margin-left: -5%;
  }
}
@media (max-width: 677px) {
  .schedules {
    font-size: 20px;
  }
  .date-title {
    font-size: 50px;
  }
  .schedule-side-container {
    font-size: 25px;
    width: 23%;
    margin-left: -5%;
    .schedule_icon {
      img {
        width: 60%;
      }
    }
  }
}
@media (max-width: 500px) {
  .schedules {
    font-size: 15px;
  }
  .date-title {
    font-size: 45px;
  }
  .schedule-side-container {
    font-size: 20px;
  }
}
</style>
