<template>
  <div class="schedule-center">
    <div class="meichu">MEICHU</div>
    <div>X</div>
    <div class="year">2019</div>
  </div>
</template>

<script>
export default {
  name: "ScheduleCenter"
};
</script>

<style scoped lang="scss">
.schedule-center {
  color: #b1b1b1;
  font-weight: bold;
  font-size: 45px;
  text-align: center;
  .meichu {
    margin-bottom: 12px;
  }
  .year {
    font-size: 200px;
    margin-top: 120px;
  }
}
@media (max-width: 1100px) {
  .schedule-center {
    display: none;
  }
}
</style>
